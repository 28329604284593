<template>
  <div>
    <el-container>
      <el-main>
        <!-- top -->
        <!-- <div class="top">
					<el-radio-group @change="onChange" v-model="radio" size="mini">
						<el-radio-button label="按人数"></el-radio-button>
						<el-radio-button label="按客单价"></el-radio-button>
						<el-radio-button label="按消费总额"></el-radio-button>
					</el-radio-group>
					<div class="right">
						<el-select v-model="value" placeholder="请选择" size="mini">
							<el-option 
							  v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-button @click="onDialog" size="mini" type="primary" icon="el-icon-plus">等级设置</el-button>
					</div>
				</div> -->
        <!-- end top -->

        <!-- charts -->
        <!-- <div class="box">
					<div id="echarts" v-show="radio == '按人数'"></div>
					<div id="echarts2" v-show="radio == '按客单价'"></div> 
					<div id="echarts3" v-show="radio == '按消费总额'"></div>
				</div> -->
        <!-- top -->
        <div class="top">
          <div class="title">分析详情</div>
          <div class="right">
            <el-select v-model="value" placeholder="请选择" size="mini">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input placeholder="请输入内容" v-model="input2" size="mini">
              <template slot="append">
                <i class="el-icon-search"></i>
              </template>
            </el-input>
            <el-button size="mini" icon="el-icon-date"></el-button>
          </div>
        </div>
        <!-- end top -->

        <!-- table -->
        <!-- <el-table
          :data="tableData"
          style="width: 100%"
          height="calc(100vh - 560px)"
        > -->
        <el-table :data="tableData" style="width: 100%" height="760">
          <el-table-column type="selection" width="48"></el-table-column>
          <el-table-column prop="date" label="昵称" width="304" fixed>
            <template slot-scope="scope">
              <div class="image-text">
                <div class="el-image">
                  <span>李</span>
                  <el-tag type="warning" size="mini">重度流失</el-tag>
                </div>
                <div class="text">
                  <div>
                    <span>我的祖国</span>
                    <i class="el-icon-male"></i>
                  </div>
                  <div class="size">13399998888</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="150"
          ></el-table-column>
          <el-table-column prop="province" label="生日" width="200">
            <el-tag size="small">公历</el-tag>
            <span>1992-09-08</span>
          </el-table-column>
          <el-table-column prop="city" label="标签" width="250">
            <el-tag size="small">微信绑定</el-tag>
            <el-tag size="small">客户来源</el-tag>
          </el-table-column>
          <el-table-column prop="city" label="会员卡" width="200">
            <el-popover placement="bottom" width="400" trigger="click">
              <div class="member-car">
                <div
                  class="member-car-list"
                  v-for="(item, index) in 10"
                  :key="index"
                >
                  <div>
                    <p>7.5折</p>
                    <p>卡号:18819191919</p>
                  </div>
                  <div>
                    <p>7.5折</p>
                    <p>18819191919</p>
                  </div>
                  <div>
                    <p>7.5折</p>
                    <p>18819191919</p>
                  </div>
                </div>
              </div>
              <span slot="reference" class="column-color">
                <i class="column-i">7.5折</i>
                <i class="el-icon-caret-bottom"></i>
              </span>
            </el-popover>
          </el-table-column>
          <el-table-column
            prop="city"
            label="疗程"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="储值金额"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="剩余积分"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="赠送金额"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="累计充值"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="欠款"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="累计消费"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="上次消费"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="建档时间"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="客单价"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="消费频率"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="跟踪员工"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="等级流失"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="跨店消费"
            width="120"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="220">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="onAlterMember"
                >修改</el-button
              >
              <el-button type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column> </el-table
        ><!-- end table -->

        <!--pagination-->
        <el-pagination
          background
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
        <!-- end pagination-->
      </el-main>
    </el-container>
  </div>
</template>

<script>
import leave from "../../../assets/js/leave.js";
export default {
  data() {
    return {
      radio: "按人数",
      value: "",
      input2: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "李四",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "张三",
          province: "200",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
      ],
    };
  },
  mounted() {
    this.onChange();
  },
  methods: {
    onChange() {
      // var echarts = document.getElementById("echarts");
      // var echarts2 = document.getElementById("echarts2");
      // var echarts3 = document.getElementById("echarts3");
      // if (this.radio == "按人数") {
      //   leave.barEChart(this, echarts);
      // } else if (this.radio == "按客单价") {
      //   leave.numberEChart(this, echarts2);
      // } else if (this.radio == "按消费总额") {
      //   leave.lineEChart(this, echarts3);
      // }
    },

    onAlterMember() {},

    onDialog() {},
  },
};
</script>

<style lang="less" scoped>
.el-container {
  .el-main {
    position: relative;
    padding: 0;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .right {
        display: flex;
        align-items: center;
      }
      .el-input {
        width: 300px !important;
        margin-left: 10px;
      }
      .el-button {
        margin-left: 10px;
      }
      .title {
        padding-left: 10px;
        border-left: 4px solid #20a0ff;
      }
    }
  }
  .box {
    margin: 20px 0;
    #echarts,
    #echarts2,
    #echarts3 {
      margin: 0 auto;
      width: 70%;
      height: 300px;
    }
  }
  .el-table {
    .image-text {
      display: flex;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 56px;
        margin-left: 10px;
        width: 200px;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .size {
          font-size: 10px;
        }
        i {
          color: #6aabdb;
          font-weight: bold;
          margin: 0 5px;
        }
      }
    }
    .el-image {
      position: relative;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 46px;
      font-weight: bold;
      font-size: 18px;
      border-radius: 5px;
      vertical-align: middle;
      background-color: #c8c5c8;
      .el-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-right: 0;
      }
    }
    .column-i {
      margin-right: 5px;
    }
    .column-color {
      color: #409eff;
    }
    .el-tag {
      margin-right: 10px;
    }
  }
  .el-pagination {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}
</style>
